/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 19:07:56
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { getShuntAbtestAll } from '../../../../../fetch/shunt';

import { UnLoginGiftDetailParam } from '../index';
import { GiftDetailUnLoginConfig2Result, GiftUnloginConfig2AbtestTemplates } from '../types';
import { MallQueryDetailResult } from '../../../../../fetch/mallGateway/types';
import { CommonParams } from '../../../../../types';

export const giftDetailUnLoginConfig2Service = async (
  giftParam: UnLoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailUnLoginConfig2Result> => {
  const {
    queryDetailParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, previewCode, lastVisitTime } = abtestParams || {};
  const abTestCommonParams = {
    productAbb,
  };
  const jiYanLoginAndGiftReloginAndGiftFormParams = {
    productAbb,
    // 重新登录需要参数
    lastVisitTime,
    // 极验需要参数
    isWifi,
  };
  // const requests = [];
  // shunt请求，分流
  const unloginAbTestParams = [
    { configCode: 'jiYanLoginAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
    { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftAutoOrderAbtest', extraLimit: abTestCommonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLandingProtocalCheckboxAbtest', extraLimit: abTestCommonParams },
    { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
  ];
  /** 请求的所有AB测编码列表，请求时赋值，用于给分流结果异常的AB测兜底 */
  const abDataArr = unloginAbTestParams.map((item) => item.configCode);
  const getAbtestData:Promise<AxiosResponse<DataResponse<GiftUnloginConfig2AbtestTemplates>, unknown>> = getShuntAbtestAll<GiftUnloginConfig2AbtestTemplates>(ybPosition, unloginAbTestParams, commonAbTestParams, commonApiParams, commonHeader);
  // 请求落地页详情
  const queryDetail:Promise<AxiosResponse<DataResponse<MallQueryDetailResult>, unknown>> = mallQueryDetail(queryDetailParams, commonApiParams, commonHeader);
  // requests.push(queryDetail);
  const [giftAbTestDataResponse, productDetailResponse] = await Promise.all([getAbtestData, queryDetail]);
  const giftAbTestData = giftAbTestDataResponse?.data;
  // ab测默认值赋值
  if (giftAbTestData && giftAbTestData.data) {
    for (const key of abDataArr) {
      const keyData = giftAbTestData.data[key];
      if (!keyData) {
        giftAbTestData.data[key] = { version: 1 };
      } else if (!keyData?.version) {
        keyData.version = 1;
      }
    }
    if (giftAbTestData.data.giftAudioAbtest) {
      giftAbTestData.data.giftAudioAbtest.version = 7; // 默认兜底版本7  产品要求
    }
  }
  return {
    abtestData: giftAbTestData,
    productData: productDetailResponse?.data,
  };
};
