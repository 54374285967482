/*
 * @Author: 朱晓真
 * @Date: 2023-04-19 11:31:24
 * @LastEditors: 朱晓真
 * @LastEditTime: 2024-06-17 19:18:03
 * @Description: 背景音频管理者
 */

import { preLoadMedia as preLoadMediaUtil } from 'src/common/utils/resourceLoadUtil';
import type BGAudioCom from 'component/BGAudioCom';

class AudioBGManager {
  // eslint-disable-next-line no-use-before-define
  private static instance: AudioBGManager;

  private preventDefaultPlayIdList = [];

  private constructor() {
    document.addEventListener('touchstart', this.handleTouchstart);
  }

  // 获取单例实例
  static getInstance(): AudioBGManager {
    if (AudioBGManager.instance) return AudioBGManager.instance;
    AudioBGManager.instance = new AudioBGManager();
    return AudioBGManager.instance;
  }

  /**
   * 当前音频组件的引用
   */
  private audioRef: BGAudioCom;

  /**
   * 当前要播放的音频
   */
  private curAudioSrc: string;

  /**
   * 播放功阻塞
   */
  private isBlock = false;

  /**
   * blockModalAudioList 弹窗锁，弹窗出现会添加一个 key，关闭的时候删除对应的 key，blockModalAudioList.size > 0 时，阻塞音频的播放
   */
  private blockModalAudioList = new Set([]);

  /**
   * 已经播放过的音频路径
   */
  audioPlayedSrcArr = [];

  pageFlagToAudioFieldNameArrMap = new Map([
    ['pzLoginPage', ['loginBGAudioSrc', 'phoneEmptyAudioSrc', 'phoneErrorAudioSrc', 'smsCodeEmptyAudioSrc', 'smsCodeErrorAudioSrc', 'pleaseFillSmsCodeAudioSrc', 'backgroundAudio', 'jiyanSound', 'jiYanAliPopup']],
    [
      'pzLandingPage',
      [
        'phoneEmptyAudioSrc',
        'phoneErrorAudioSrc',
        'smsCodeEmptyAudioSrc',
        'smsCodeErrorAudioSrc',
        'certNoEmptyAudioSrc',
        'certNoErrorAudioSrc',
        'nameEmptyAudioSrc',
        'nameErrorAudioSrc',
        'nameCertNoMatchErrorAudioSrc',
        'page2BGAudioSrc',
        'createOrderPerModalBGAudioSrc',
        'obtainingSecurityPlanAudioSrc',
        'petNameErrAudioSrc',
        'page1BgAudio',
        'page2BgAudio',
        'formLeave',
      ],
    ],
    ['batchUpgrade', ['upgradeBGAudioSrc']],
    ['resultBatchLoad2', ['improveSecurityAudioSrc', 'backgroundAudio']],
    ['giftResultPage', [
      'zdyjpSound',
      'detailNameEmpty',
      'detailCertNoPage',
      'detailNameError',
      'detailCertNoError',
      'detailCertNoMatchErrorAudioSrc',
      'zxtcSound',
      'clickButtonToImproveSecurityAudioSrc',
    ]],
  ]);

  private handleTouchstart = (e) => {
    const currentId = e.target?.id;
    for (const id of this.preventDefaultPlayIdList) {
      if (currentId === id) return;
    }
    this.playBGAudio(this.curAudioSrc);
  };

  /**
   * 阻塞背景音频播放
   * isBlock：全局锁，主要在页面初始化的时候使用
   * blockModalAudioList：弹窗锁，弹窗显示加锁，弹窗关闭解锁
   * key: 如果有 key，表示是出现了一系列的弹窗，需要阻断音频的播放，弹窗关闭之后不再播放任何音频
   */
  blockBGAudio(key?: string) {
    if (key) {
      if (this.blockModalAudioList.has(key)) {
        console.error('该 ab 测弹窗已经被加锁，不能播放音频', key);
        return;
      }
      this.blockModalAudioList.add(key);
    } else {
      this.isBlock = true;
    }
    this.pauseBGAudio();
  }

  /**
   * 关闭阻塞-重新播放
   * isBlock：全局锁，主要在页面初始化的时候使用
   * blockModalAudioList：弹窗锁，弹窗显示加锁，弹窗关闭解锁
   * key: 如果有 key，表示是出现了一系列的弹窗，需要阻断音频的播放，弹窗关闭之后不再播放任何音频
   */
  closeBGAudioBlock = (key?: string) => {
    if (key) {
      if (!this.blockModalAudioList.has(key)) {
        console.error('该 ab 测弹窗已经被解锁过', key);
        return;
      }
      this.blockModalAudioList.delete(key);
      return;
    }
    this.isBlock = false;
    this.playBGAudio();
  };

  /**
   * 播放音频
   * @param src 传递则播放新的，不传递就播放记录的要播放的src
   * @param forcePLay 强制播放音频，不受阻塞和已经播放过的限制
   * @param preventDefaultPlayIdList 设置禁止触发播放的dom id列表
   * @param trackParams 埋点参数
   * @returns void
   */
  playBGAudio = (src?: string, forcePLay?: boolean, preventDefaultPlayIdList?: Array<string>, trackParams?: Record<string, unknown>) => {
    this.curAudioSrc = src || this.curAudioSrc;
    if (preventDefaultPlayIdList) this.preventDefaultPlayIdList = preventDefaultPlayIdList;
    if (forcePLay && this.curAudioSrc && this.audioRef) {
      this.audioRef.play(this.curAudioSrc, true, trackParams);
      return;
    }
    // 需要检查一下全局锁和弹窗锁，有任一存在，都不能播放音频
    if (this.blockModalAudioList.size > 0 || this.isBlock || !this.curAudioSrc || !this.audioRef || this.audioPlayedSrcArr.includes(this.curAudioSrc.trim())) return;
    this.audioRef.play(this.curAudioSrc, false, trackParams);
  };

  /**
   * 强制重新播放
   * @param src 传递则播放新的，不传递就播放记录的要播放的src
   * @param trackParams 埋点额外参数
   * @tips 第一次是简单播放，第二次会强制从头开始播放
   */
  forceRePlay = ({ src, trackParams }) => {
    this.curAudioSrc = src || this.curAudioSrc;
    if (this.curAudioSrc && this.audioRef) {
      this.audioRef.play(this.curAudioSrc, true, trackParams);
    }
  };

  /**
   * 暂停播放当前音频
   * @param isResetStart 是否重置播放进度
   */
  pauseBGAudio = (isResetStart = false) => {
    this.audioRef?.pause?.();
    if (isResetStart) {
      this.audioRef?.setCurrentTime();
    }
  };

  /**
   * 重置音频播放
   */
  resetAudioPlay = () => {
    this.curAudioSrc = ''; // 重置当前音频
    this.audioPlayedSrcArr = []; // 重置历史播放记录
  };

  /**
   * 挂载ref媒体组件对象
   * @param audioRef
   */
  mountAudioRef = (audioRef?: any) => {
    this.audioRef = audioRef;
    if (!audioRef) {
      this.resetAudioPlay();
      return; // 卸载之前的audio组件的时候，直接返回
    }
    this.audioPlayedSrcArr = [];
  };

  handleOnPlay = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    const audioEle = event.target as HTMLAudioElement;
    this.audioPlayedSrcArr.push(audioEle?.src || this.curAudioSrc);
  };

  /**
   * 预加载媒体
   * @param pzAudioAbtest
   * @param pageFlag 页面标志
   */
  preLoadMedia = (audioAbtest: any, pageFlag: 'pzLoginPage' | 'pzLandingPage' | 'batchUpgrade' | 'resultBatchLoad2' | 'giftResultPage') => {
    const audioFieldArr = this.pageFlagToAudioFieldNameArrMap.get(pageFlag);
    audioFieldArr?.forEach((fieldName) => {
      if (audioAbtest[fieldName]) preLoadMediaUtil(audioAbtest[fieldName]);
    });
  };
}

export default AudioBGManager;
