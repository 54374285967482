/*
 * @Author: surun
 * @Date: 2022-11-02 20:35:28
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-07-29 10:51:15
 * @Description: 跳转相关
 */
import { TianjiFetch } from 'fetch';
import { Toast } from '@yb/fe-component';
import { openHrefUrl } from 'store/UrlTools';
import { isWeMini } from 'utils/device';
import { isShouxin, isCN } from 'ybcommon/ybutils/common/company';

// 获取打开小程序的scheme 并拉起小程序
export const jumpToMini = (path, query, userIdencrypt = '') => {
  TianjiFetch.post('api/mall/miniapp/api/miniapp/generateScheme', {
    path,
    query,
    userIdencrypt,
  })
    .then((data) => {
      if (data) window.location.href = data;
    })
    .catch((err) => {
      Toast.fail(err);
    });
};

// 获取打开小程序的UrlLink 拉起小程序
export const jumpToMiniByUrlLink = (path, query) => {
  TianjiFetch.post('api/mall/miniapp/api/miniapp/generateURLLink', { path, query })
    .then((data) => {
      if (data) window.location.href = data;
    })
    .catch((err) => {
      Toast.fail(err);
    });
};

/**
   * 跳转到小程序
   * @param {*} jumpUrl 跳转地址
   * @return {void}
   */
export const jumpToMiniProgram = (jumpUrl, isReplace = false) => {
  console.log('[jumpUtil][jumpToMiniProgram]jumpUrl: ', jumpUrl);
  if (isWeMini()) {
    if (isShouxin()) {
      const tabbarPages = [
        '/sxmp/pages/home/index',
        '/sxmp/pages/product/index',
        '/sxmp/pages/my/index',
      ];
      if (tabbarPages.find((x) => x.includes(jumpUrl))) {
        window.wx.miniProgram.switchTab({ url: jumpUrl });
        return;
      }
    }
    if (isReplace) {
      window.wx.miniProgram.redirectTo({
        url: jumpUrl,
      });
    } else {
      window.wx.miniProgram.navigateTo({
        url: jumpUrl,
      });
    }
  } else {
    const [path, query = ''] = jumpUrl.split('?');
    jumpToMini(path, query);
  }
};

/**
   * 使用小程序打开一个H5页面
   * @param {string} h5url
   */
export const openH5InMini = (h5url) => {
  const miniWebViewPath = '/ybsc/pages/web/index';
  const miniWebViewQuery = `webPath=${encodeURIComponent(h5url)}&type=3`;
  jumpToMini(miniWebViewPath, miniWebViewQuery);
};

/**
   * 根据跳转类型跳转
   * @param {*} type 0-h5页面 1-小程序页面  2-第三方页面 3-小程序内h5 -1 不跳转
   * @param {*} jumpUrl 跳转地址
   */
export const jumpByEnvType = (type, jumpUrl) => {
  if (type === 0) {
    openHrefUrl(jumpUrl);
  } else if (type === 1) {
    jumpToMiniProgram(jumpUrl);
  } else if (type === 2) {
    window.location.href = jumpUrl;
  } else if (type === 3) {
    const jumpType = jumpUrl.indexOf('https://') === 0 ? 3 : 4;// 3: 完整链接 4:相对路径
    let appName = '';
    if (isCN() || __DEV__) {
      appName = 'ybsc';
    } else if (isShouxin()) {
      appName = 'sxmp';
    }
    const newUrl = `/${appName}/pages/web/index?type=${jumpType}&webPath=${encodeURIComponent(jumpUrl)}`;
    jumpToMiniProgram(newUrl);
  }
};
