/*
 * @Author: liulongjie liulongjie@yuanbaobaoxian.com
 * @Date: 2022-07-01 15:30:40
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-04-20 10:46:10
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/resultNew/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/resultNew',
    routes: [
      {
        path: '/resultNew/detail',
        component: () => import('./index'),
      },
      {
        path: '/resultNew/cxdetail',
        component: () => import('./index'),
      },
      {
        path: '/resultNew/batch',
        component: () => import('./batch'),
      },
      {
        path: '/resultNew/renewal',
        component: () => import('./renewal'),
      },
      {
        path: '/resultNew/renewalList',
        component: () => import('./renewalList'),
      },
      {
        path: '/resultNew/result',
        component: () => import('./renewalResult'),
      },
      {
        path: '/resultNew/petReplenish', // 宠物险补充页面
        component: () => import('./PetReplenish'),
      },
      {
        path: '/resultNew/coupleUpgrade', // 1+12均分组合链路的升级页
        component: () => import('./CoupleUpgrade'),
      },
      {
        path: '/resultNew/batchBackPreview', // 批增升级页cms返回弹窗预览页
        component: () => import('./batch/UpgradeVersion1/BackModalPreview'),
      },
    ],
  },
  {
    path: '/result/pay',
    component: () => import('./PayResult/index'),
  },
  {
    path: '/result/productdetail',
    component: () => import('./optimizeResult/index.tsx'),
  },
];
