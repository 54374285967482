// 极意h5接入文档 https://docs.geetest.com/onelogin/deploy/h5

import { Toast } from '@yb/fe-component';
import { url, storage, UserUtil } from 'utils';
import { isShouxin } from 'ybcommon/ybutils/common/company';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { isAlipay, isWechat, isAlipayClient } from 'utils/device';
import { UCFetch, TianjiFetch } from 'fetch';
import { getTgReferer } from 'ybcommon/ybmodel/tgUtil';

import { Facade } from 'src/plugins/facade/index';
import stores from './index';
import { OPERATOR_NUM, SUPPLIER, JIYAN_VERSION } from './jiYanLoginAbtestModel/constant';
import { DEMOTE_UTIL } from './jiYanLoginAbtestModel/utils/demoteUtil';

// 是否正在登录请求中
let isLoginSending = false;

const fail = (err, failCB) => {
  if (failCB) {
    failCB(err);
  } else {
    Toast.fail(err);
  }
};

export const loginSuccess = (loginData, phone, successCB, failCB, channel) => {
  const { userId = '', token = '', unionId, openId, isRegiste } = loginData || {};
  UserUtil.setItem('userId', userId, true);
  UserUtil.setItem('token', token, true);

  stores.userStore.userId = userId;
  stores.userStore.userToken = token;
  const { isWeChatMiniApp, appId } = stores.userStore;
  const miniData = { userId, userToken: token, isWeChatMiniApp };
  if (+isWeChatMiniApp === 1 || +isWeChatMiniApp === 5) {
    // 元宝商城小程序
    window.wx.miniProgram.postMessage({ data: { userId, userToken: token } });
    stores.userStore.unionId = unionId;
    Object.assign(miniData, { openId, unionId, appId });
  } else if ([6, 7, 8].includes(+isWeChatMiniApp)) {
    // 支付宝投诉通道
    stores.userStore.unionId = unionId;
    Object.assign(miniData, { unionId });
  } else if (+isWeChatMiniApp === 9 || +isWeChatMiniApp === 10) {
    // 元宝商城小程序
    window.tt.miniProgram.postMessage({ data: { userId, userToken: token } });
    stores.userStore.unionId = unionId;
    Object.assign(miniData, { openId, unionId, appId });
  }
  storage.set('_MINIDATA_', miniData);
  if (window.location.pathname === '/cargo/login') {
    stores.userStore.loginTsp = new Date().getTime();
  }
  Promise.all([TianjiFetch.post('/api/mall/bff/init', { type: isAlipay() ? 2 : 1 }), UCFetch.post('/api/init/getUserInfo')])
    .then((res) => {
      const [data = {}, dataUC] = res;
      const { user } = data;
      const coreData = Object.assign(data, { user: { ...user, ...dataUC } });
      stores.userStore.phone = phone || dataUC.phone;
      stores.userStore.hasLogin = true;
      stores.userStore.updateCoreData(coreData, channel);
      if (successCB) successCB(phone || dataUC.phone, isRegiste, loginData);
    })
    .catch((err) => {
      fail(err, failCB);
    });
};

// 人脸识别登录
export const faceLogin = async (userId, successCB, failCB) => {
  // 获取matainfo信息
  let matainfoStr = '';
  try {
    const data = await Facade.getMetainfo();
    if (data?.matainfo) matainfoStr = JSON.stringify(data.matainfo);
  } catch (error) {
    Toast.fail('matainfo获取失败');
    return;
  }

  // 获取token
  const requestParams = {
    metainfo: matainfoStr,
    userId,
  };
  let token = '';
  try {
    const tokenRes = await UCFetch.post('api/login/getVerificationToken', requestParams);
    token = tokenRes?.verificationToken;
    console.log('**token**', token);
  } catch (error) {
    Toast.fail('网络异常，请选择其他登录方式');
    return;
  }

  if (Facade.verify) {
    try {
      const faceResult = await Facade.verify({ verificationToken: token });
      console.log('**faceResult**', faceResult);
      if (faceResult && faceResult.result === 'success') {
        const urlObj = url(window.location.href);
        const { params } = urlObj || {};
        const { channel: _c } = params || {};
        const { channel: uChannel } = stores.userStore;
        const channel = uChannel || _c || '';
        UCFetch.post('api/login/faceIdentificationLogin', { verificationToken: token, userId }).then((res) => {
          console.log('***result---res****', res);
          loginSuccess(res, '', successCB, failCB, channel);
        }).catch((err) => {
          fail(err, failCB);
        });
      } else {
        Toast.fail('人脸识别失败，请使用手机号登录');
      }
    } catch (err) {
      Toast.fail('人脸识别失败，请使用手机号登录');
    }
  }
};

export const doLogin = (phone, smsCode, successCB, failCB) => {
  if (isLoginSending) {
    // 正在请求中，不要重复请求
    return;
  }
  isLoginSending = true;
  const urlObj = url(window.location.href);
  const { params } = urlObj || {};
  const { channel: _c, position = '' } = params || {};
  const { channel: uChannel, unionId: wxUnionId } = stores.userStore;
  const channel = uChannel || _c || '';
  let unionId = null;
  if (isWechat() || isAlipayClient()) {
    unionId = wxUnionId;
  }
  const openId = stores.userStore.getOpenId();
  const tgReferer = getTgReferer();

  setTimeout(() => {
    UCFetch.loadingPost(
      {
        url: '/api/login/phoneLogin',
        params: {
          phone,
          smsCode,
          openId,
          unionId,
          channel: channel || position ? `${channel}|${position}` : '',
          referer: tgReferer,
        },
      },
      (loginData) => {
        isLoginSending = false;
        loginSuccess(loginData, phone, successCB, failCB, channel);
      },
      (err) => {
        isLoginSending = false;
        fail(err, failCB);
      },
    );
  }, 0);
};

// 极验版本4一键登录
export const oneJiYanLogin = (checkCB, successCB, failCB, onStatic, tokenFail) => {
  // if (isLoginSending) {
  // 正在请求中，不要重复请求
  // return;
  // }
  const reportCommonParams = { version: JIYAN_VERSION.V4, type: JIYAN_VERSION.V4, supplier: SUPPLIER.JY };
  const _trackEvent = typeof onStatic === 'function' ? onStatic : trackEvent;

  // 失败回调，reason：原因，err：接口错误信息
  const toFailLogin = (reason, err, isUserCenter) => {
    const operator = OPERATOR_NUM[err?.data?.operator?.toLowerCase()];
    _trackEvent('jiyanfail', { ...reportCommonParams, reason, operator, ...(err ? { err } : {}) });
    // isLoginSending = false;
    failCB();
    DEMOTE_UTIL.sendLog({
      fromVersion: JIYAN_VERSION.V4,
      currentVersion: JIYAN_VERSION.V3,
      overTimeType: isUserCenter ? 3 : 1,
      operator: operator || '',
      supplier: SUPPLIER.JY,
    });
  };

  // isLoginSending = true;
  const urlObj = url(window.location.href);
  const { params } = urlObj || {};
  const { channel: _c, position = '' } = params || {};
  const { channel: uChannel, unionId: wxUnionId } = stores.userStore;
  const channel = uChannel || _c || '';
  const unionId = isWechat() ? wxUnionId : null;
  const openId = stores.userStore.getOpenId();

  /**
   * 对接极验登录 h5一键登录实例
   * 文档地址： https://docs.geetest.com/onelogin/deploy/h5#H5%E4%B8%80%E9%94%AE%E7%99%BB%E5%BD%95-OneLogin-API
   */
  const opParams = {
    app_id: __PUB__ ? '6aa28075e805880d19ace4eda139b3d8' : '7777de789b8953320bccc54774db45e8', // 在极验后台获取，生成app_id后等待极验审核成功后生效
    timeout: 3000, // 超时时长，默认30000
    logo: 'https://cdn.ybaobx.com/upload/jiyanLogo.png',
    app: '元保',
    product: 'popup',
    keyboardOpen: true,
    // customerSwitchText: '其他登录方式',
    buttonBackgroundActive: '#FF6D00',
    logoWidth: '259px',
  };
  if (isShouxin()) opParams.app_id = __PUB__ ? '14c9c73027715ef7378cd53dea7d6cbc' : 'bee94b5e03c6c3905978463c6c6b4702';

  DEMOTE_UTIL.setStartTime();
  if (!window.GOL) {
    toFailLogin('GOL不存在');
    return;
  }

  const opInstance = new window.GOL(opParams);
  if (!opInstance) {
    toFailLogin('未挂载实例');
    return;
  }
  const jiyan1Time = new Date().getTime();
  _trackEvent('h5_fe_startjiyan1', reportCommonParams);

  // 调用网关接口
  opInstance.gateway();

  opInstance.onTokenSuccess((data) => {
    const tgReferer = getTgReferer();
    // 网关成功，获取校验结果
    const param = {
      channel: channel || position ? `${channel}|${position}` : '',
      prosessId: data.process_id,
      accessCode: data.accesscode,
      phone: data.phone,
      loginType: 3, // 1-APP一键登录；2-APP本机校验；3-H5一键登录；4-H5本机校验
      openId,
      unionId,
      token: data.token,
      referer: tgReferer,
    };

    const jiyan2Time = new Date().getTime();
    DEMOTE_UTIL.setStartTime();
    _trackEvent('h5_fe_startjiyan2', { ...reportCommonParams, interval: jiyan2Time - jiyan1Time });

    const loginFn = () => {
      const startTime = new Date().getTime();
      UCFetch.loadingPost(
        {
          url: '/api/login/jiyanOneLogin',
          params: param,
          postConfig: { timeout: 3000 },
        },
        (res) => {
          _trackEvent('jiyansuccess', { ...reportCommonParams, operator: OPERATOR_NUM[res?.operator?.toLowerCase()] });
          // isLoginSending = false;
          const endTime = new Date().getTime();
          _trackEvent('jiYan4TimeStep2', { status: 'success', interval: endTime - startTime });
          loginSuccess(res, data.phone, successCB, failCB, channel);
        },
        (err) => {
          Toast.fail(err);
          const endTime = new Date().getTime();
          _trackEvent('jiYan4TimeStep2', { status: 'fail', interval: endTime - startTime });
          toFailLogin('用户中心接口失败', err, true);
        },
      );
    };
    const check = checkCB(failCB, loginFn);

    if (!check) {
      loginFn();
    }
  });

  opInstance.onTokenFail((err) => {
    toFailLogin('网关失败', err);
    if (typeof tokenFail === 'function') {
      tokenFail();
    }
  });
};

/**
   * 判断当前环境是否是 wifi 环境
   * @param {*} param0
   * @returns true：是 wifi 环境，false：不是 wifi 环境
   * https://docs.geetest.com/onelogin/deploy/h5
   */
export const checkIsWifiLink = () => {
  const opParams = {
    app_id: __PUB__ ? 'a50e6c73273571aa1b7fcbcd1cfb9ba5' : '7e47dc6c04de44f0962d135891fa25ff', // 在极验后台获取，生成app_id后等待极验审核成功后生效
    timeout: 3000, // 超时时长，默认30000
    pre_init: false,
  };
  if (isShouxin()) opParams.app_id = __PUB__ ? '25a4e3fff86381df84bf70864e72243f' : 'fa6d32d2f3a2dc0fe4e32f6817a89a92';

  if (!window.GOP) {
    return false;
  }

  const opInstance = new window.GOP(opParams);
  if (!opInstance) {
    return false;
  }

  // 调用checkNetInfo 方法获取用户的网络状态
  const net = opInstance.checkNetInfo();
  if (net === 'wifi') {
    // 调用网关验证
    return true;
  }
  return false;
};

export const jiYanLogin = (phone, successCB, failCB, onStatic, hittedVersion) => {
  const _trackEvent = typeof onStatic === 'function' ? onStatic : trackEvent;

  if (isLoginSending) {
    // 正在请求中，不要重复请求
    return;
  }
  const reportCommonParams = { version: hittedVersion, type: JIYAN_VERSION.V3, supplier: SUPPLIER.JY };
  // 走验证码登录，reason：原因，err：接口错误信息
  const toSmsLogin = (reason, err, isUserCenter) => {
    const operator = OPERATOR_NUM[err?.data?.operator?.toLowerCase()];
    _trackEvent('jiyanfail', { ...reportCommonParams, reason, operator, ...(err ? { err } : {}) });
    isLoginSending = false;
    failCB();
    DEMOTE_UTIL.sendLog({
      fromVersion: JIYAN_VERSION.V3,
      currentVersion: 1,
      overTimeType: isUserCenter ? 3 : 1,
      operator: operator || '',
      supplier: SUPPLIER.JY,
    });
  };

  isLoginSending = true;
  const urlObj = url(window.location.href);
  const { params } = urlObj || {};
  const { channel: _c, position = '' } = params || {};
  const { channel: uChannel, unionId: wxUnionId } = stores.userStore;
  const channel = uChannel || _c || '';
  const unionId = isWechat() ? wxUnionId : null;
  const openId = stores.userStore.getOpenId();

  /**
   * 对接极验登录 h5本机校验实例
   * 文档地址： https://docs.geetest.com/onelogin/deploy/h5#OnePass-API-%E6%8E%A8%E8%8D%90%E9%80%BB%E8%BE%91
   */
  const opParams = {
    app_id: __PUB__ ? 'a50e6c73273571aa1b7fcbcd1cfb9ba5' : '7e47dc6c04de44f0962d135891fa25ff', // 在极验后台获取，生成app_id后等待极验审核成功后生效
    timeout: 3000, // 超时时长，默认30000
    pre_init: true, // 是否初始化时获取运营商参数，默认为true。 建议开启避免影响认证的响应时间
  };
  if (isShouxin()) opParams.app_id = __PUB__ ? '25a4e3fff86381df84bf70864e72243f' : 'fa6d32d2f3a2dc0fe4e32f6817a89a92';
  DEMOTE_UTIL.setStartTime();
  if (!window.GOP) {
    toSmsLogin('GOP不存在');
    return;
  }

  const opInstance = new window.GOP(opParams);
  if (!opInstance) {
    toSmsLogin('未挂载实例');
    return;
  }
  const jiyan1Time = new Date().getTime();
  _trackEvent('h5_fe_startjiyan1', reportCommonParams);

  // 调用网关接口
  opInstance.gateway({ phone });

  opInstance.onGatewaySuccess((data) => {
    const tgReferer = getTgReferer();
    // 网关成功，获取校验结果
    const param = {
      channel: channel || position ? `${channel}|${position}` : '',
      prosessId: data.process_id,
      accessCode: data.accesscode,
      phone: data.phone,
      loginType: 4, // 1-APP一键登录；2-APP本机校验；3-H5一键登录；4-H5本机校验
      openId,
      unionId,
      referer: tgReferer,
    };

    DEMOTE_UTIL.setStartTime();
    const jiyan2Time = new Date().getTime();
    _trackEvent('h5_fe_startjiyan2', { ...reportCommonParams, interval: jiyan2Time - jiyan1Time });
    UCFetch.loadingPost(
      {
        url: '/api/login/jiyanLogin',
        params: param,
        postConfig: { timeout: 3000 },
      },
      (res) => {
        _trackEvent('jiyansuccess', { ...reportCommonParams, operator: OPERATOR_NUM[res?.operator?.toLowerCase()] });
        isLoginSending = false;
        loginSuccess(res, phone, successCB, failCB, channel);
      },
      (err) => {
        toSmsLogin('用户中心接口失败', err, true);
      },
    );
  });

  opInstance.onGatewayFail((err) => {
    toSmsLogin('网关失败', err);
  });
};

// 极验 网关校验
export const preJiYanLoginAsync = async (phone, onStatic) => {
  const _trackEvent = typeof onStatic === 'function' ? onStatic : trackEvent;
  /**
   * 对接极验登录 h5本机校验实例
   * 文档地址： https://docs.geetest.com/onelogin/deploy/h5#OnePass-API-%E6%8E%A8%E8%8D%90%E9%80%BB%E8%BE%91
  */
  const opParams = {
    app_id: __PUB__ ? 'a50e6c73273571aa1b7fcbcd1cfb9ba5' : '7e47dc6c04de44f0962d135891fa25ff', // 在极验后台获取，生成app_id后等待极验审核成功后生效
    timeout: 3000, // 超时时长，默认30000
    pre_init: true, // 是否初始化时获取运营商参数，默认为true。 建议开启避免影响认证的响应时间
  };
  if (isShouxin()) opParams.app_id = __PUB__ ? '25a4e3fff86381df84bf70864e72243f' : 'fa6d32d2f3a2dc0fe4e32f6817a89a92';

  if (!window.GOP) {
    return { isSuccess: false, errMsg: 'GOP不存在' };
  }
  const opInstance = new window.GOP(opParams);
  if (!opInstance) {
    return { isSuccess: false, errMsg: '未挂载实例' };
  }
  const jiyan1Time = new Date().getTime();
  _trackEvent('h5_fe_startjiyan1', { type: 4 });

  const gatewayRes = await new Promise((resolve) => {
    // 调用网关接口
    opInstance.gateway({ phone });

    opInstance.onGatewaySuccess((data) => {
      resolve({ isSuccess: true, data, jiyan1Time });
    });

    opInstance.onGatewayFail((err) => {
      resolve({ isSuccess: false, err, errMsg: '网关失败' });
    });
  });
  return gatewayRes;
};

// 极验 登录
export const postJiYanLoginAsync = async (data, jiyan1Time, onStatic) => {
  const _trackEvent = typeof onStatic === 'function' ? onStatic : trackEvent;
  const jiyan2Time = new Date().getTime();
  _trackEvent('h5_fe_startjiyan2', { type: 4, interval: jiyan2Time - jiyan1Time });

  const urlObj = url(window.location.href);
  const { params } = urlObj || {};
  const { channel: _c, position = '' } = params || {};
  const { channel: uChannel, unionId: wxUnionId } = stores.userStore;
  const channel = uChannel || _c || '';
  const unionId = isWechat() ? wxUnionId : null;
  const openId = stores.userStore.getOpenId();
  const tgReferer = getTgReferer();
  const jiyanLoginParam = {
    channel: channel || position ? `${channel}|${position}` : '',
    prosessId: data.process_id,
    accessCode: data.accesscode,
    phone: data.phone,
    loginType: 4, // 1-APP一键登录；2-APP本机校验；3-H5一键登录；4-H5本机校验
    openId,
    unionId,
    referer: tgReferer,
  };

  const loginRes = await new Promise((resolve) => {
    UCFetch.loadingPost(
      {
        url: '/api/login/jiyanLogin',
        params: jiyanLoginParam,
      },
      (res) => { resolve({ isSuccess: true, res }); },
      (err) => { resolve({ isSuccess: false, err }); },
    );
  });
  if (!loginRes.isSuccess) {
    return { isSuccess: false, err: loginRes.err, errMsg: '用户中心接口失败' };
  }
  _trackEvent('jiyansuccess', { type: 4 });
  const result = await new Promise((resolve) => {
    loginSuccess(
      loginRes.res,
      jiyanLoginParam.phone,
      (phone, isRegiste) => { resolve({ isSuccess: true, phone, isRegiste }); },
      (err) => { resolve({ isSuccess: false, err }); },
      channel,
    );
  });
  if (!result.isSuccess) {
    return { isSuccess: false, err: result.err };
  }
  return { ...result };
};
