/*
 * @Author: shixiaoxia
 * @Date: 2023-12-06 15:31:04
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-03-25 21:25:51
 * @Description: 授权相关信息
 */
import { UCFetch } from 'ybcommon/ybfetch';
import { Toast } from '@yb/fe-component';

/*
* @description: 微信授权
* @params: appCode 第三方应用编码
* @params: authType 授权类型 1-强制授权 2-静默授权
* @params: companyCode 授权公司编码
* @params: sourceApp 来源应用  WEB_PAY_APP-web支付 SOURCE_APP_WEB_APP_SILENCE-公众号静默授权登录
* @params: skipAuthCb 跳过静默授权回调 已登录/关闭openid登录开关/接口报错
* @params: authCb 授权前回调
*/
export const doWxAndZhiFuBaoAuth = (appCode, authType, companyCode, sourceApp = 'WEB_PAY_APP', skipAuthCb = null, authCb = null) => {
  const params = {
    appCode, // || getAppCode(),
    authType, // authType: 2, // 静默授权
    companyCode, // || getBusinessOrigin(),
    sourceApp,
    wechatRedirect: window.location.href.split('#')[0],
    redirectUrl: window.location.href.split('#')[0],
  };
  UCFetch.post('api/auth/wx_redirect', params).then((res) => {
    if (skipAuthCb && (res === 'checkPass' || !res)) {
      // 登录态|关闭openid登录开关
      skipAuthCb(res === 'checkPass');
      return;
    }
    // 授权前回调
    if (authCb) authCb();
    if (res) window.location.replace(res);
  }).catch((err) => {
    if (skipAuthCb) {
      // 兼容后端接口报错
      skipAuthCb(false);
      return;
    }
    Toast.fail(err);
  });
};

// 微信内调支付api支付
export const doWxPay = (params) => {
  const onBridgeReady = () => {
    if (!__DEV__) {
      // eslint-disable-next-line no-undef
      if (WeixinJSBridge && params) {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke('getBrandWCPayRequest', { ...params, package: params.packageValue });
      }
    }
  };
  // if (isWechat()) {
  if (__DEV__) {
    onBridgeReady();
  } else if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
  // }
};

export const doAlipayAuth = (appCode, companyCode, sourceApp) => new Promise((resolve, reject) => {
  let appId = '2021003145645842';
  if (__PUB__) {
    appId = '2021002150673762';
  } else if (window.location.host.indexOf('dev') > -1) {
    appId = '2021002153671892';
  }
  window.ap?.getAuthCode({
    appId,
    scopes: ['auth_base'],
    showErrorTip: false,
  }, (res1) => {
    const { authCode } = res1 || {};
    if (authCode) {
      const params = {
        code: authCode,
        appCode,
        sourceApp,
        companyCode,
      };
      UCFetch.post('api/auth/silentAuth', params).then((res) => {
        resolve(res?.unionId);
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject(null);
    }
  });
});
