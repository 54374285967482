export default {
  path: '/schoolroom',
  routes: [
    {
      path: '/schoolroom/detail',
      component: () => import('./index'),
    },
    {
      path: '/schoolroom/sq',
      component: () => import('./index'),
    },
    {
      path: '/schoolroom/dic',
      component: () => import('./Dictionary'),
    },
    {
      path: '/schoolroom/video',
      component: () => import('./Video'),
    },
  ],
};
