/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 10:57:35
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { OrderAcceptstatisticResult, OrderPersonCheckResult } from '../../../../../fetch/bff/types';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { ifHasGiftOrder, queryOrderAcceptstatistic, queryOrderAcceptstatisticPersonCheck } from '../../../../../fetch/bff';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { isRequestError } from '../../../../../utils/httperr';
import { getShuntAbtestAll } from '../../../../../fetch/shunt';
import { LoginGiftDetailParam } from '../index';
import { GiftConfig1AbtestTemplates } from '../../types';
import { GiftDetailLoginConfig1Result } from '../types';
import { CommonParams } from '../../../../../types';

const getCmsFormData = (pageData: Record<string, any>, type: 'loginForm' | 'infoForm') => {
  if (type === 'loginForm') {
    return pageData.unLogin.list.find((item: Record<string, any>) => item.name === 'custom-login-form').props;
  }
  return pageData.login.list.find((item: Record<string, any>) => item.name === 'custom-info-form').props;
};

const _isPromotionOptimizeForm = (pageData: Record<string, any>) => getCmsFormData(pageData, 'infoForm').styleType === 4;

export const giftDetailLoginConfig1Service = async (
  giftParam: LoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailLoginConfig1Result> => {
  const {
    queryOrderParams,
    queryDetailParams,
    ifHasOrderParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, landingPageVersion } = abtestParams || {};
  const { shuntId } = commonApiParams;
  const commonParams = {
    version: landingPageVersion,
    productAbb,
  };
  // shunt请求，分流
  const abTestParams = [
    { configCode: 'newVisionAbtest', extraLimit: { ...commonParams, isWifi } },
    { configCode: 'idCardAbtest', extraLimit: commonParams },
    { configCode: 'giftAudioAbtest', extraLimit: commonParams },
    { configCode: 'jiYanLoginAbtest', extraLimit: { ...commonParams, isWifi } },
    { configCode: 'page1Abtest', extraLimit: commonParams }, // 赠险中的投保提示以及协议展示仅给保司校验用 目前只能接入版本4
    { configCode: 'page2Abtest', extraLimit: commonParams },
    { configCode: 'giftLoginBackAbtest', extraLimit: commonParams, isWifi },
    { configCode: 'giftDetailBackAbtest', extraLimit: commonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: commonParams },
    { configCode: 'giftAutoOrderAbtest', extraLimit: commonParams },
    { configCode: 'giftpayLoadingAbtest', extraLimit: commonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: commonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: commonParams, shuntId },
  ];
  const giftAbTestDataResponse = await getShuntAbtestAll<GiftConfig1AbtestTemplates>(ybPosition, abTestParams, commonAbTestParams, commonApiParams, commonHeader);
  const productAbTest = giftAbTestDataResponse?.data?.data;
  const { newVisionAbtest } = productAbTest || {};
  // 针对newVision处理详情页数据
  const { jumpUrl, landPageVersion } = newVisionAbtest || {};
  if (jumpUrl) {
    return {
      abtestData: giftAbTestDataResponse?.data,
    };
  }
  // 最终落地页版本
  const finalLandVersion = (landPageVersion || landingPageVersion || 0) as number;
  const mallQueryDetailParams = {
    ...queryDetailParams,
    landingPageVersion: finalLandVersion, // 批增版本未使用，先统一传0
  };
  // 请求落地页详情
  const productDetailResponse = await mallQueryDetail(
    mallQueryDetailParams,
    commonApiParams,
    commonHeader,
  );
  const productDetailErr = isRequestError(productDetailResponse?.data);
  if (productDetailErr) {
    return {
      abtestData: giftAbTestDataResponse?.data,
      productData: productDetailResponse?.data,
    };
  }
  const ifHasOrderResponse = await ifHasGiftOrder(ifHasOrderParams, commonApiParams, commonHeader);
  // const { ifHasOrder, insuredOrderNum } = ifHasOrderResponse?.data?.data || {};
  // const { landingPageVo } = productDetailResponse?.data?.data || {};
  // const { pageData } = landingPageVo?.pageInfo || {};
  // const canSelectRelation = _findProductProps(pageData, 'login', 'custom-info-form', 'canSelectRelation');
  // 只是为了拿到是否有订单
  // const hasOrder = +canSelectRelation === 1 ? +ifHasOrder === 1 && insuredOrderNum >= 8 : +ifHasOrder === 1;
  if (isRequestError(ifHasOrderResponse?.data)) {
    return {
      abtestData: giftAbTestDataResponse?.data,
      productData: productDetailResponse?.data,
      orderData: ifHasOrderResponse?.data,
    };
  }
  const safeGetRelationMemeberData = async ():Promise<AxiosResponse<DataResponse<OrderPersonCheckResult | OrderAcceptstatisticResult>>> => {
    try {
      let res = null;
      const { landingPageVo } = productDetailResponse?.data?.data || {};
      const { pageData } = landingPageVo?.pageInfo || {};
      if (_isPromotionOptimizeForm(pageData)) {
        res = await queryOrderAcceptstatisticPersonCheck(queryOrderParams, commonApiParams, commonHeader);
      } else {
        res = await queryOrderAcceptstatistic(queryOrderParams, commonApiParams, commonHeader);
      }
      return res;
    } catch (err) {
      return err;
    }
  };
  const homeMemberResponse = await safeGetRelationMemeberData();
  return {
    abtestData: giftAbTestDataResponse?.data,
    productData: productDetailResponse?.data,
    orderData: ifHasOrderResponse?.data,
    homeMemberData: homeMemberResponse?.data,
  };
};
