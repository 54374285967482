/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2022-10-21 09:38:39
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-08-13 09:53:09
 * @Description: 理赔相关
 */
import serviceRoute from './service/route';

export default [{
  path: '/claim',
  component: () => import('./index'),
  routes: [
    {
      path: '/claim/videoStory', // 理赔视频页
      component: () => import('./videoStory'),
    },
    {
      path: '/claim/detail', // 理赔详情页
      component: () => import('./detail'),
    },
    ...serviceRoute,
  ],
}, {
  path: '/allowance',
  component: () => import('./allowance'),
  title: '健康测评',
  routes: [
    {
      path: '/allowance/imagecollect',
      title: '我的认证',
      component: () => import('./allowance/ImagesCollect'),
    },
  ],
}];
