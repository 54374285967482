/*
 * @Author: 朱晓真
 * @Date: 2023-06-13 11:14:25
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2024-07-22 15:33:03
 * @Description: 资源加载工具--主要处理图片、音频等的预加载等功能
 */
import { device } from 'fe-yb-tools';
import axios from 'axios';

const { isIOS } = device;
/**
   * 预加载音视频--建议不要放在页面init前，因为预加载占用请求资源
   * @param {*} src
   * @returns
   */
const preLoadMedia = (src) => {
  if (!src) return Promise.reject();
  if (isIOS()) { // 苹果手机使用xmlhttp方式预加载，因为ios手机Audio预加载不起作用，只有在播放的时候才会请求资源
    return axios.get(src, { responseType: 'blob' });
  }
  // 安卓手机使用audio预加载
  let audioObj;
  try {
    audioObj = new Audio(src);
  } catch (error) {
    if (window.yb && window.yb.uplogCatchError) {
      window.yb.uplogCatchError(JSON.stringify({ code: 'AudioCreateError', msg: { message: `预加载音频异常=src=${src}`, stack: '' } }));
    }
  }

  return new Promise((resolve) => {
    audioObj?.addEventListener('canplaythrough', () => {
      /* 音频完全下载下来 */
      resolve();
    });
  });
};

/**
 * 批量预加载图片资源
 * @param {Array<string>} srcList 图片src列表
 */
export function preLoadImage(srcList) {
  if (!Array.isArray(srcList)) return;

  const fetchImage = () => {
    setTimeout(() => {
      for (const src of srcList) {
        const img = new Image();
        img.src = src;
      }
    }, 0);
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', fetchImage);
  } else {
    fetchImage();
  }
}

/**
 * 批量预加载图片资源和资源size
 * @param {Array<{key: string, src: string}>} srcList 图片src列表
 */
export function preLoadImageAndGetSize(srcList, successCB) {
  if (!Array.isArray(srcList)) return;

  const loadedData = {};
  // 已经加载完几个图片
  let loadedCount = 0;

  // 图片已经加载完成
  const loadedImgData = (key, srcItem, img) => {
    // 图片已经有缓存
    // 直接取原始尺寸
    loadedData[key] = {
      ...srcItem,
      size: {
        width: img.width,
        height: img.height,
      },
    };
    loadedCount += 1;
    if (loadedCount === srcList.length) {
      // 所有图片加载完成，回调放回图片数据
      successCB?.(loadedData);
    }
  };

  const fetchImage = () => {
    setTimeout(() => {
      srcList.forEach((item) => {
        const img = new Image();
        img.src = item.src;
        const key = `${item.key}`;
        if (img.complete) {
          loadedImgData(key, item, img);
        } else {
          img.onload = function () {
            loadedImgData(key, item, img);
          };
        }
      });
    }, 0);
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', fetchImage);
  } else {
    fetchImage();
  }
}

export { preLoadMedia };
