/*
 * @Author: liulongjie liulongjie@yuanbaobaoxian.com
 * @Date: 2022-07-01 15:30:40
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2024-02-18 13:57:38
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/service/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/service',
    routes: [
      {
        path: '/service/introduce',
        component: () => import('./Introduce'),
      },
      {
        path: '/service/counselor',
        component: () => import('./Adviser'),
      },
      {
        path: '/service/sop',
        component: () => import('./sop'),
        registerOwnShare: {
          yuanbao: true,
        },
      },
      {
        path: '/service/bindwhite',
        component: () => import('./bindwhite'),
      },
      {
        path: '/service/redirect',
        component: () => import('./Redirect'),
      },
    ],
  },
  {
    path: '/collect', // 收集信息页
    component: () => import('./collect'),
    exact: true,
  },
];
