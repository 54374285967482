export default {
  path: '/strategy',
  component: () => import('./index'),
  routes: [
    {
      path: '/strategy/detail',
      component: () => import('./Detail'),
    },
  ],
};
