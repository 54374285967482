import api from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../node_modules/css-loader/dist/cjs.js?{\"modules\":{\"localIdentName\":\"[local]_[hash:base64:8]\"},\"sourceMap\":false}!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../node_modules/less-loader/dist/cjs.js?{\"javascriptEnabled\":true}!./index.less";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};