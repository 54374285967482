/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-13 20:25:15
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { CommonParams } from '../../../../../types';
import { OrderAcceptstatisticResult } from '../../../../../fetch/bff/types';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { ifHasGiftOrder, queryOrderAcceptstatistic } from '../../../../../fetch/bff';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { BaseShuntAbResult, getShuntAbtestAll } from '../../../../../fetch/shunt';
import { LoginGiftDetailParam } from '../index';
import { GiftDetailLoginConfig2Result } from '../types';
import { GiftUnloginConfig2AbtestTemplates, GiftDetailUnLoginConfig2Result } from '../../unlogin/types';

export interface GiftLoginConfig2AbtestTemplates {
  giftLinkAbtest: BaseShuntAbResult;
  giftFormAbtest: BaseShuntAbResult;
  giftAudioAbtest: BaseShuntAbResult;
  giftFormTipsAbtest: BaseShuntAbResult;
  giftFormHeaderImgAbtest: BaseShuntAbResult;
  giftLoginAndLandingLongImageAbtest: BaseShuntAbResult;
  giftPage1Abtest: BaseShuntAbResult;
  giftPage2Abtest: BaseShuntAbResult;
  giftDetailBackAbtest: BaseShuntAbResult;
  idCardAbtest: BaseShuntAbResult;
  giftAutoOrderAbtest: BaseShuntAbResult;
  greetingVariantAbtest: BaseShuntAbResult;
  giftProConfirmAbtest: BaseShuntAbResult;
  giftHandAbtest: BaseShuntAbResult;
  giftProtocolAbtest: BaseShuntAbResult;
  giftLandingProtocalCheckboxAbtest: BaseShuntAbResult;
  formPermanentResidentIDCardAbtest: BaseShuntAbResult;
  giftreLoginABtest: BaseShuntAbResult;
  [key: string]: BaseShuntAbResult | undefined;
}

export const giftDetailLoginConfig2Service = async (
  giftParam: LoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailLoginConfig2Result | GiftDetailUnLoginConfig2Result> => {
  const {
    queryOrderParams,
    queryDetailParams,
    ifHasOrderParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, previewCode, lastVisitTime, needReLogin } = abtestParams || {};
  const abTestCommonParams = {
    productAbb,
  };
  const jiYanLoginAndGiftReloginAndGiftFormParams = {
    productAbb,
    // 重新登录需要参数
    lastVisitTime,
    // 极验需要参数
    isWifi,
  };
  const requests = [];
  // shunt请求，分流
  const loginAbTestParams = [
    { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
    { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftAutoOrderAbtest', extraLimit: abTestCommonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLandingProtocalCheckboxAbtest', extraLimit: abTestCommonParams },
    { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
  ];
  if (needReLogin) { // 如果非微信、抖音小程序，需要增加重新登录
    loginAbTestParams.push({ configCode: 'giftreLoginABtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams });
  }
  /** 请求的所有AB测编码列表，请求时赋值，用于给分流结果异常的AB测兜底 */
  let abDataArr = loginAbTestParams.map((item) => item.configCode);
  requests.push(getShuntAbtestAll<GiftLoginConfig2AbtestTemplates>(ybPosition, loginAbTestParams, commonAbTestParams, commonApiParams, commonHeader));
  // 请求落地页详情
  requests.push(mallQueryDetail(queryDetailParams, commonApiParams, commonHeader));
  // 登陆态或者有加密userid，获取是否领取+家庭成员信息和领取状态（不进行合并是为了避免中间有请求失败导致接口异常）
  const safeGetOrderData = async () => {
    try {
      const res = await ifHasGiftOrder(ifHasOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };
  const safeGetRelationMemeberData = async ():Promise<AxiosResponse<DataResponse<OrderAcceptstatisticResult>>> => {
    try {
      const res = await queryOrderAcceptstatistic(queryOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };
  requests.push(safeGetOrderData());
  requests.push(safeGetRelationMemeberData());
  const [giftAbTestDataResponse, productDetailResponse, ifHasOrderResponse, homeMemberResponse] = await Promise.all(requests);
  const { giftreLoginABtest } = giftAbTestDataResponse?.data?.data || {};
  let unloginGiftAbTestDataResponse;
  // 如果是重新登陆，则需要重新请求非登录态ab测
  if (giftreLoginABtest?.version > 1) {
    const unloginAbTestParams = [
      { configCode: 'jiYanLoginAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
      { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftAutoOrderAbtest', extraLimit: abTestCommonParams },
      { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLandingProtocalCheckboxAbtest', extraLimit: abTestCommonParams },
      { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
    ];
    abDataArr = unloginAbTestParams.map((item) => item.configCode);
    unloginGiftAbTestDataResponse = await getShuntAbtestAll<GiftUnloginConfig2AbtestTemplates>(ybPosition, unloginAbTestParams, commonAbTestParams, commonApiParams, commonHeader);
  }
  const giftAbTestData = unloginGiftAbTestDataResponse ? unloginGiftAbTestDataResponse?.data : giftAbTestDataResponse?.data;
  // ab测默认值赋值
  if (giftAbTestData && giftAbTestData.data) {
    for (const key of abDataArr) {
      const keyData = giftAbTestData.data[key];
      if (!keyData) {
        giftAbTestData.data[key] = { version: 1 };
      } else if (!keyData?.version) {
        keyData.version = 1;
      }
    }
    if (giftAbTestData.data.giftAudioAbtest) {
      giftAbTestData.data.giftAudioAbtest.version = 7; // 默认兜底版本7  产品要求
    }
    // 如果重新登陆需要在原ab测结果上增加重新登陆ab测
    if (giftreLoginABtest?.version > 1) {
      giftAbTestData.data.giftreLoginABtest = giftreLoginABtest;
    }
  }
  // 如果是重新登陆，则只需要返回ab测数据和产品详情数据
  if (giftreLoginABtest?.version > 1) {
    return {
      abtestData: giftAbTestData,
      productData: productDetailResponse?.data,
    };
  }
  return {
    abtestData: giftAbTestData,
    productData: productDetailResponse?.data,
    orderData: ifHasOrderResponse?.data,
    homeMemberData: homeMemberResponse?.data,
  };
};
