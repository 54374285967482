export default {
  path: '/people',
  routes: [
    {
      path: '/people/edit',
      component: () => import('./Edit'),
    },
    {
      path: '/people/add',
      component: () => import('./Edit'),
    },
    {
      path: '/people/list',
      component: () => import('./List'),
    },
  ],
};
