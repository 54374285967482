/**
 * 自定义身份证号键盘组件
 */
import React, { Component, Fragment } from 'react';
import { getLocationSearch, judgeIsShuntPreview } from 'ybcommon/ybutils/common/common';
import { device } from 'fe-yb-tools';
import stores from 'store';

import './index.less';

const { isAndroid, isIOS } = device;

const { userStore, toolStore } = stores;

class CustomKeyboard extends Component {
  static defaultProps = {
    onKeyboardClick: () => { }, // 按钮点击回调
  };

  state = {
    show: false, // 组件是否显示
  };

  // 获取当前URL是否是来自b端平台预览URL相关参数
  params = getLocationSearch(window?.location?.search || '');

  // UNSAFE_componentWillMount() {
  //   this.randomKey = Math.floor(Math.random() * 10000 + 1);
  //   console.log('setDisplay....', toolStore.setDisplay);
  // }

  componentWillUnmount() {
    this.clearTimer();
    userStore.keyboardShowAndroid = false;
    toolStore.changeCustomKeyBoardShow(false);
  }

  // 控制显示与否
  // eslint-disable-next-line react/no-unused-class-component-methods
  setDisplay = (flag) => {
    if (flag) {
      toolStore.customKeyboard = this;
    } else {
      toolStore.customKeyboard = null;
    }
    this.setState({
      show: flag,
    }, () => {
      const { show } = this.state;
      toolStore.changeCustomKeyBoardShow(show);
      if (isAndroid()) { // 模拟安卓键盘展示
        if (flag) {
          userStore.keyboardShowAndroid = true;
        } else {
          userStore.keyboardShowAndroid = false;
        }
      }
    });
  };

  // // 点击或长按键盘时 改变按钮颜色
  // changeColor = (flag, key) => {
  //   const onFocusButton = document.getElementById(key);
  //   if (onFocusButton && flag) {
  //     onFocusButton.classList.add('onfocusButton');
  //   }
  //   if (onFocusButton && !flag) {
  //     onFocusButton.classList.remove('onfocusButton');
  //   }
  // };

  // // 是否长按按钮
  checkLongPress = (pressButton) => {
    if (this.pressTimer) {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    }
    this.pressTimer = setTimeout(() => {
      // console.log('long press');
      this.islong = true;
      this.longPress(pressButton);
    }, 800);
  };

  // 长按事件
  // 长按delete连续删除
  // 长按其他键只触发一次
  longPress = (pressButton) => {
    const { onKeyboardClick } = this.props;
    if (this.deleteTimer) {
      clearInterval(this.deleteTimer);
    }
    if (pressButton !== 'delete') {
      if (onKeyboardClick) onKeyboardClick(pressButton);
      return;
    }
    this.deleteTimer = setInterval(() => {
      if (onKeyboardClick) onKeyboardClick(pressButton);
    }, 100);
  };

  clearTimer = () => {
    if (this.pressTimer) {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    }
    if (this.deleteTimer) {
      clearInterval(this.deleteTimer);
      this.deleteTimer = null;
    }
  };

  // 判断当前页面是否在pc端
  _isPC = () => (!/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Silk/.test(navigator?.userAgent));

  // 获取键盘按键点击需要监听的事件名称集合
  _getEventKeys = () => {
    // PC端 且是来自b端平台预览，需要将触摸事件改为点击事件监听
    if (this._isPC() && ((this.params.previewCode && this.params.isCmsPreview) || judgeIsShuntPreview())) {
      return ['onMouseDown', 'onMouseMove', 'onMouseUp'];
    }

    return ['onTouchStart', 'onTouchMove', 'onTouchEnd'];
  };

  // 获取键盘按键点击需要监听的事件集合
  _getEventListeners = (item, onKeyboardClick) => {
    const { show } = this.state;
    const [start, move, end] = this._getEventKeys();

    const eventListeners = {
      [start]: () => {
        this.islong = false;
        // this.changeColor(true, id);
        // console.log('start....');
        const resValue = item === '尾数X' ? 'X' : item;
        this.checkLongPress(resValue);
      },
      [move]: () => {
        // this.changeColor(false, id);
        // console.log('move...');
        this.clearTimer();
      },
      [end]: () => {
        // this.changeColor(false, id);
        // console.log('end...');
        this.clearTimer();
        if (!this.islong) {
          if (isIOS() && !show) return; // 解决iOS兼容性问题，防止页面滚动到底部时，touch事件触发隐藏键盘输入
          const resValue = item === '尾数X' ? 'X' : item;
          if (onKeyboardClick) onKeyboardClick(resValue);
        }
      },
    };

    return eventListeners;
  };

  _renderList = (list) => <div className="yb-keyboard-list">{list.map(this._renderItem)}</div>;

  // 键盘渲染
  _renderItem = (item, index) => {
    const { onKeyboardClick } = this.props;
    const key = `${item}_${index + 1}`;

    const eventListeners = this._getEventListeners(item, onKeyboardClick);

    // const id = `${key}_${this.randomKey}`;
    return (
      <div
        key={key}
        // id={id}
        className={`yb-keyboard-item ${item === '尾数X' ? 'yb-keyboard-item-x' : ''}`}
        {...eventListeners}
      // onClick={() => {
      //   const resValue = item === '尾数X' ? 'X' : item;
      //   if (onKeyboardClick) onKeyboardClick(resValue);
      // }}
      // onTouchStart={}
      // onTouchMove={}
      // onTouchEnd={}
      // onTouchCancel={() => {
      //   if (onKeyboardClick) onKeyboardClick(item);
      //   // this.changeColor(false, id);
      //   this.clearTimer();
      // }}
      >
        {item === 'delete' ? this._renderIcon() : item}
      </div>
    );
  };

  _renderIcon = () => <div className="icon-delete" />;

  _renderTop = () => {
    const { onKeyboardClick, idCardAbtest } = this.props;
    const showTopImg = [3, 4, 5, 6].includes(idCardAbtest?.version) && idCardAbtest?.topImg;
    const confirmBtnClass = [3, 4, 6].includes(idCardAbtest?.version) ? `yb-keyboard-hide${idCardAbtest.version}` : 'yb-keyboard-hide';
    return (
      <div
        className="yb-keyboard-top"
      >
        {showTopImg ? (<img className="topImg" src={idCardAbtest?.topImg} alt="" />) : (
          <Fragment>
            <div className="icon-dun" />
            <div className="yb-keyboard-txt">您的信息将严格保密</div>
          </Fragment>
        )}
        <div
          className={confirmBtnClass}
          onClick={() => {
            if (onKeyboardClick) onKeyboardClick('hide');
          }}
        >
          完成
        </div>
      </div>
    );
  };

  render() {
    const { show } = this.state;
    const { idCardAbtest } = this.props;
    console.log('show...', show);
    const extraClass = [3, 4, 6].includes(idCardAbtest?.version) ? `yb-keyboard-wrapper${idCardAbtest.version}` : '';
    return (
      <div
        className={`yb-keyboard-wrapper ${extraClass} ${!show ? 'hide' : ''} `}
        onTouchStart={(e) => {
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {this._renderTop()}
        {this._renderList(['1', '2', '3'])}
        {this._renderList(['4', '5', '6'])}
        {this._renderList(['7', '8', '9'])}
        {this._renderList(['尾数X', '0', 'delete'])}
      </div>
    );
  }
}

export default CustomKeyboard;
