import axios, {
  AxiosRequestConfig, AxiosResponse, AxiosInstance,
  AxiosError,
} from 'axios';
import { getFetchMsg, getFetchSign } from '../utils/requestDataEncode';
import { getLogger } from '../webLogger';
import { getEnvConfigrationByDomain } from '../envConfig';

export class CustomAxiosError extends Error {
  constructor(code: number, msg: string, err: Error) {
    super(err.message);
    this.msg = msg;
    this.code = code;
    this.err = err;
    this.data = { code, msg };
  }

  data: { code: number, msg: string};

  code: number;

  msg: string;

  err: Error;
}

const shuntOssQueryUrl = ['api/growth/shunt/default/determine/batchGetPage', 'api/growth/shunt/default/determine/getPage'];

/**
 * 公用header
 */
export interface CommonHeader {
  // userId?: string;
  // userToken?: string;
  // uuid:string;
  // clientIp:string;
  [key: string ] : string;
}

export interface DataResponse<T> {
  data?: T; // 数据
  code: number; // 状态码 200 OK
  msg: string;
  success?: boolean;
}

/**
 * 获取请求实例
 * @param requestConfig 请求配置
 * @param request 请求拦截器
 * @param response 响应拦截器
 * @returns axios实例
 */
export const getAxiosInstance = (
  requestConfig: AxiosRequestConfig,
  request: (req: AxiosRequestConfig) => AxiosRequestConfig,
  response: (res: AxiosResponse) => AxiosResponse,
  error: (res: AxiosError) => AxiosError,
): AxiosInstance => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: '/',
    // TODO: 超时时间需要根据运行在Server端还是前端来决定
    timeout: Number(getEnvConfigrationByDomain().API_TIMEOUT || 5000),
    transformRequest: [],
    ...requestConfig,
  });
  axiosInstance.interceptors.request.use(request);
  axiosInstance.interceptors.response.use(response, error);
  return axiosInstance;
};

const handleResData = (res: AxiosResponse, code: string | number, msg: string, data: Record<string, unknown>) => {
  const resCode = Number(code);
  let resMsg = msg;
  switch (resCode) {
    case 201:
      resMsg = '未登录';
      break;
    case 408:
      resMsg = '请求超时，请稍后重试';
      break;
    case 502:
    case 504:
      resMsg = msg || '网络不给力，请稍后重试';
      break;
    case 9999:
      resMsg = '';
      break;
    case 666: // 重定向特殊状态码
      resMsg = '';
      break;
    default:
      break;
  }
  return {
    ...res,
    data: {
      code: resCode,
      msg: resMsg,
      data,
    },
  };
};

export const baseFetch = (
  _axionConfig:AxiosRequestConfig,
  commonHeader: CommonHeader,
  reqIntercepter?: (resData: Record<string, unknown>) => Record<string, unknown>,
):AxiosInstance => getAxiosInstance(
  {
    ..._axionConfig,
    // eslint-disable-next-line func-names
    transformRequest: [(data):string | undefined => {
      if (data) {
        const msg = getFetchMsg(data);
        const sign = getFetchSign(msg);
        getLogger().info('request', { requestMsg: msg, requestSign: sign });
        // Do whatever you want to transform the data
        return `msg=${msg}&sign=${sign}`;
      }
      return '';
    }],
  },
  (req: AxiosRequestConfig) => {
    req.headers = {
      ...req.headers,
      ...commonHeader,
    };
    getLogger().info('request', { baseURL: req.baseURL, url: req.url, requestMsgHeader: req.headers });
    return req;
  },
  (res: AxiosResponse) => {
    const { status, config } = res || {};
    // TODO: 补充用于定位问题的信息
    getLogger().info('response', { baseURL: config.baseURL, url: config.url, responseData: res.data });
    if (config?.method === 'get') {
      if (status === 200) {
        return handleResData(res, 200, '', reqIntercepter ? reqIntercepter(res.data) : res.data);
      }
    }
    if (/json/.test(res.headers['content-type'])) {
      // shunt oss 请求成功后会直接返回结果，没有code，因此直接返回结果
      if (config?.url && shuntOssQueryUrl.indexOf(config?.url) > -1 && status === 200) {
        return handleResData(res, 200, '', reqIntercepter ? reqIntercepter(res.data) : res.data);
      }
      const { retcode, retCode, retdesc, retDesc, ...data } = res.data;
      // 兼容处理
      const code = Number(retcode || retCode);
      const msg = retdesc || retDesc;

      if (Number.isNaN(code) || code === 200) {
        return handleResData(res, 200, '', reqIntercepter ? reqIntercepter(data.data) : data.data);
      }
      // return processError({ code, msg, data: data.data });
      return handleResData(res, code, msg, data.data);
    }
    return res; // response对象
  },
  (err: AxiosError) => {
    // getLogger().info('response', err);
    const msg = err?.response?.data?.msg || '抱歉，网络异常，请检查网络或稍后重试';
    const code = err?.response?.data?.status || err?.response?.status || 99;
    const data = {
      code,
      msg,
    };

    if (!err.response) {
      err.response = {
        status: 500,
        statusText: err.message,
        data,
        config: { data: null },
        headers: {},
      };
    } else {
      err.response.data = data;
    }

    throw new CustomAxiosError(code, msg, err);
  }
);
