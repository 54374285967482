import React from 'react';
import { isShouxin, isYB, isKc } from 'ybcommon/ybutils/common/company';

import HomeRoute from './home/route';
import HealthRoute from './health/route';
import ProductRoute from './product/route';
import ServiceRoute from './service/route';
import OrderRoute from './order/route';
import PromoteRoute from './promote/route';
import GiftRoute from './gift/route';
import YinliuRoute, { cxCalculateRoute } from './yinliu/route';
import ErrorRoute from './error/route';
import ClaimRoute from './claim/route';
import PayRoute from './pay/route';
import QuestionRoute from './question/route';
import ReportRoute from './report/route';
import SchoolRoute from './schoolroom/route';
import PublicityRoute from './publicity/route';
import RisktestRoute from './risktest/route';
import MyRoute from './my/route';
import ResultNewRoute from './resultNew/route';
import ExtraRoute from './extra/route';
import StrategyRoute from './strategy/route';
import WalletRoute from './wallet/route';
import PeopleRoute from './people/route';
import ActivityRoute from './activity/route';
import ShuntRoute from './shunt/route';
import yunfu from './yunfu/route.js';
import consult from './consult/route.js';
import aliRoute from './order/ali/route';
import videoLiveRoute from './videoLive/route';
import personInfoRoute from './personInfo/route';
// 搭售相关预览页面
import Preview from './preivew/route';

import TestRoute from './test/route';

// 关于我们相关页面
import AboutRoute from './about/route';

// 权益
import Rights from './rights/route';

// 首信路由
import ShouxinRoute from './shouxin/route';

// 科创路由
import KcRoute from './kc/route';

// 可视化页面路由
import VisualRoute from './visual/route';

// 企微引导关注福利页
import QiweiRoute from './qiwei/route';

// 合规相关页面
import Compliance from './compliance/route';
// 登录判断页
import LoginRoute from './login/route';
/* 小贷页面路由 */
import SmallLoanRoute from './activity/smallLoan/route';

const commonRoutes = [
  ...OrderRoute, // 订单
  PeopleRoute, // 添加家人
  ...ProductRoute, // 产品相关
  PublicityRoute, // 保单宣传页
  PayRoute, // 主动支付及支付中间相关页
  ...ResultNewRoute, // 投保结果页
  ExtraRoute, // 增值服务页
  {
    path: '/public/new', // 公众号页（自动拉起小程序）
    component: () => import('./public/index'),
    exact: true,
  },
  ...VisualRoute, // 可视化相应路径（对应可视化平台配置）
  AboutRoute, // 关于我们
  ...ServiceRoute, // 1v1服务
  ErrorRoute,
  ShuntRoute, // 分流中间页
  ...Preview, // 2.0预览路径
  {
    path: '/customer', // 客服中间页
    component: () => import('./kfAuthor'),
    exact: true,
  },
  ...Compliance,
  ReportRoute, // 保障月报
  HealthRoute, // 健康金相关页
  ...GiftRoute, // 赠险相关页，xa+jkj
  ...yunfu,
  ...consult,
  ...ActivityRoute, // 活动相关页
  ...videoLiveRoute,
  cxCalculateRoute, // 长险测算
  ...personInfoRoute,
  ...ClaimRoute, // 理赔相关页
];

// 所有路由
const oriroutes = [
  ...commonRoutes,
  ...HomeRoute, // 首页
  StrategyRoute, // 保险攻略
  PromoteRoute, // 短信相关页
  QuestionRoute, // 问答相关页
  SchoolRoute, // 课堂
  RisktestRoute, // 风险测评
  ...MyRoute, // 我的页
  ...YinliuRoute, // 推广引流页
  WalletRoute, // 钱包页（已废弃）
  ...Preview, // 预览页面
  ...Rights, // 权益相关页（含福利保障）
  QiweiRoute,
  LoginRoute,
];

// ybaobx路由，限制gift/detail相关页面和产品落地页展示
const ybaobxRoutes = [
  {
    path: '/gift/detail',
    component: () => import('./product/ProductDetail/index'),
  },
  {
    path: '/resultNew/detail',
    component: () => import('./resultNew/index'),
  },
  {
    path: '/cargo/detail',
    component: () => import('./product/ProductDetail/index'),
  },
  {
    path: '/wheelDisc',
    component: () => import('./activity/wheelDisc/Detail/index'),
  },
  {
    path: '/lucky',
    component: () => import('./activity/lucky/Base/index'),
  },
  {
    path: '/gift/suppliersqy',
    component: () => import('./rights/Suppliers'),
  },
  {
    path: '/rights/use',
    component: () => import('./rights/RightsUse'),
  },
  {
    path: '/welfare/address',
    component: () => import('./rights/welfare/Address/index'),
  },
  {
    path: '/vipDay/address',
    component: () => import('./rights/vipDay/address'),
    exact: true,
  },
  {
    path: '/zszq/withdraw',
    component: () => import('./rights/zszq/withdraw'),
    exact: true,
  },
  {
    path: '/zszq/succ',
    component: () => import('./rights/zszq/succ'),
    exact: true,
  },
  ShuntRoute,
  {
    path: '/youjia/address',
    component: () => import('./rights/youjia/Address'),
    exact: true,
  },
  {
    path: '/youjia/succ',
    component: () => import('./rights/youjia/Succ'),
    exact: true,
  },
  {
    path: '/youjia/six/middle',
    component: () => import('./rights/youjia/SixForOne/Middle'),
  },
  {
    path: '/youjia/six/productdetail',
    component: () => import('./rights/youjia/SixForOne/ProductDetail'),
  },
  {
    path: '/youjia/six/orderlist',
    component: () => import('./rights/youjia/SixForOne/OrderList'),
  },
  {
    path: '/youjia/six/orderDetail',
    component: () => import('./rights/youjia/SixForOne/OrderDetail'),
  },
  {
    path: '/youjia/six/productaddress',
    component: () => import('./rights/youjia/SixForOne/AddressSix'),
  },
  {
    path: '/youjia/six/logistics',
    component: () => import('./rights/youjia/SixForOne/Logistics'),
  },
  SmallLoanRoute,
  ErrorRoute,
  aliRoute,
  ...ActivityRoute.filter((routes) => routes.path === '/getrights' || routes.path === '/simulation'),
  ...VisualRoute,
  ...Compliance,
  {
    path: '/gift/middle',
    component: () => import('./product/giftMiddle'),
  },
];

const sxRoutes = [
  ...ShouxinRoute,
  ...commonRoutes,
];

const kcRouts = [
  ...KcRoute,
  ErrorRoute,
];

// 根据域名返回不同的路由
// eslint-disable-next-line import/no-mutable-exports
let routes = oriroutes;
if (isYB()) {
  routes = ybaobxRoutes;
} else if (isShouxin()) {
  routes = sxRoutes;
} else if (isKc()) {
  // 科创线上不允许访问商城链路
  // if (__PUB__) routes = [];
  routes = kcRouts;
}

if (!__PUB__) {
  routes.push(TestRoute);
} else if (!isKc()) {
  routes.push({
    path: '/test/pay',
    component: () => import('./test/PayTest'),
  });
}

export default routes;
