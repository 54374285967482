export default {
  path: '/risktest',
  routes: [
    {
      path: '/risktest/result',
      component: () => import('./result/index'),
    },
    {
      path: '/risktest/question',
      component: () => import('./question/index'),
    },
  ],
};
