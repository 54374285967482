/*
 * @Author: xiaotao xiaotao@yuanbaobaoxian.com
 * @Date: 2023-11-24 11:55:31
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-04-17 17:57:55
 * @FilePath: /fe-insurance-audio/packages/fe-main-web/src/sys/home/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEP
 */
export default [{
  path: '/',
  component: () => import('./index'),
  exact: true,
},
{
  path: '/textDetail',
  component: () => import('./InsuranceClass/ClassDetail'),
  exact: true,
}, {
  path: '/videoHomePage',
  component: () => import('./VideoPage'),
  registerOwnShare: {
    yuanbao: true,
  },
  exact: true,
}];
